import { colors, createTheme } from '@mui/material';
import breakpoints from './breakpoints';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  spacing: 4,
  shadows,
  breakpoints,
  typography,
  palette: {
    primary: {
      main: '#449fbb',
      contrastText: '#fff',
    },
    secondary: {
      main: colors.blue[500],
      contrastText: '#fff',
    },
    success: {
      main: colors.green[400],
    },
    error: {
      main: colors.red[400],
    },
  },
});

export default theme;
