import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { PostBody, useMaintenanceEvents } from '../../../api/maintenance';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MaintenanceForm from '../../../components/MaintenanceForm/MaintenanceForm';
import { Inputs } from '../../../components/MaintenanceForm';

const MaintenanceRegister = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const { postMaintenanceEvent } = useMaintenanceEvents();
  const navigate = useNavigate();

  const handleRegister = useCallback(
    async (data: Inputs) => {
      setIsRegistering(true);

      const body: PostBody = {
        start_time: data.start_time?.toJSDate().toISOString() || '',
        end_time: data.end_time?.toJSDate().toISOString() || '',
        type: data.type,
        incident_level: data.incident_level,
        target_services: data.target_services,
        status: data.status,
        summary: {
          en: data.summary_en,
          ja: data.summary_ja,
        },
      };

      if (data.type === 'scheduled') {
        delete body['incident_level'];
      }
      if (data.type === 'unplanned' && !data.is_end_time) {
        delete body['end_time'];
      }

      const res = await postMaintenanceEvent(body);

      if (res.isSuccess()) {
        navigate('/maintenance/');
      }

      if (res.isFailure()) {
        //
      }

      setIsRegistering(false);
    },
    [postMaintenanceEvent, navigate],
  );

  return (
    <Box my={4}>
      <Box mb={6}>
        <Breadcrumbs>
          <Link component={RouterLink} to="/maintenance/">
            Maintenance
          </Link>
          <Typography color="text.primary">Register</Typography>
        </Breadcrumbs>
      </Box>
      <Typography variant="h5">Register Maintenance Event</Typography>
      <Box>
        <MaintenanceForm
          isRegistering={isRegistering}
          onSubmit={handleRegister}
        />
      </Box>
    </Box>
  );
};

export default React.memo(MaintenanceRegister);
