import {
  authenticationManager,
  User,
} from '@tier4/webauto-auth-frontend-client';
import { atom } from 'recoil';

export default authenticationManager({
  authority: import.meta.env.VITE_OIDC_PROVIDER_ENDPOINT ?? '',
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID ?? '',
  client_secret: import.meta.env.VITE_OIDC_CLIENT_SECRET ?? '',
  redirect_uri: import.meta.env.VITE_OIDC_LOGIN_CALLBACK_URI ?? '',
  silent_redirect_uri: import.meta.env.VITE_OIDC_LOGIN_CALLBACK_URI ?? '',
  tokenServiceAPIUrl: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}`,
  post_logout_redirect_uri: import.meta.env.VITE_OIDC_LOGOUT_CALLBACK_URI ?? '',
  automaticSilentRenew: true,
  scope: 'openid',
  logLevel: 'error',
  logMethod: {
    ...console,
    error(errorMessage: string) {
      console.log(errorMessage);
    },
  },
  afterSignOutPropagation: () => {
    window.location.href = '/';
  },
});

export const userAtom = atom<User | null>({
  key: 'userAtom',
  default: null,
});
