import {
  faCircleCheck,
  faCircleExclamation,
  faRotateRight,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useMount } from 'react-use';
import { useStatusInfo } from '../../api/status';
import { DISPLAY_SERVICE_NAMES, Service } from '../../api/types';

type StatusRowProps = {
  data: Service;
  name: string;
};

const StatusRow = memo<StatusRowProps>(({ data, name }) => {
  const theme = useTheme();

  const status = useMemo(() => {
    if (data.status === 'available') {
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="lg"
          color={theme.palette.success.main}
        />
      );
    }
    if (data.status === 'not_available') {
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          size="lg"
          color={theme.palette.error.main}
        />
      );
    }
    if (data.status === 'partially_available') {
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="lg"
          color={theme.palette.warning.main}
        />
      );
    }
  }, [data.status, theme]);

  return (
    <TableRow>
      <TableCell>
        <Grid container alignItems="center" spacing={6}>
          <Grid>
            <Box
              sx={{
                backgroundImage: `url(/assets/services/${name}/icon.png)`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 40,
                height: 40,
              }}
            />
          </Grid>
          <Grid>
            <Typography>{DISPLAY_SERVICE_NAMES[name]}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">{status}</TableCell>
    </TableRow>
  );
});

StatusRow.displayName = 'StatusRow';

const Status = () => {
  const { getStatusInfo } = useStatusInfo();
  const [statuses, setStatuses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadStatusInfo = useCallback(async () => {
    setIsLoading(true);
    const res = await getStatusInfo();
    if (res.isSuccess()) {
      setStatuses(res.value);
    } else {
      setStatuses(null);
    }
    setIsLoading(false);
  }, [getStatusInfo]);

  useMount(() => {
    loadStatusInfo();
  });

  return (
    <Box my={4}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid>
          <Typography variant="h5">Service Status</Typography>
        </Grid>
        <Grid>
          <Button
            disabled={isLoading}
            onClick={loadStatusInfo}
            startIcon={
              <FontAwesomeIcon icon={faRotateRight} style={{ width: 14 }} />
            }
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      <Box>
        {isLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          statuses && (
            <Box mt={4}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Service</TableCell>
                      <TableCell variant="head" align="right">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(statuses).map((key) => (
                      <StatusRow key={key} name={key} data={statuses[key]} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default Status;
