import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { getAPI, cuAPI, SERVICE_NAMES } from '.';
import {
  Failure,
  MaintenanceIncidentLevel,
  MaintenanceStatus,
  MaintenanceSummary,
  MaintenanceType,
  Success,
} from './types';

export type PostBody = {
  summary: MaintenanceSummary;
  start_time: string;
  end_time?: string;
  status: MaintenanceStatus;
  type: MaintenanceType;
  target_services: string[];
  incident_level?: MaintenanceIncidentLevel;
};

export type UnplannedPostBody = Omit<PostBody, 'end_time'>;
export type ScheduledPostBody = Omit<PostBody, 'incident_level'>;

export const useMaintenanceEvents = () => {
  const { enqueueSnackbar } = useSnackbar();

  const getMaintenanceEvents = useCallback(
    async (startFrom: string, startTo: string) => {
      try {
        const instance = getAPI();
        const params = new URLSearchParams();
        params.append('start_from', startFrom);
        params.append('start_to', startTo);
        SERVICE_NAMES.forEach((name) => params.append('service_name', name));
        const res = await instance.get(
          `/maintenance_events?${params.toString()}`,
        );
        return new Success(res.data.maintenance_event_list);
      } catch (err) {
        enqueueSnackbar('Failure to get maintenance events', {
          variant: 'error',
        });
        return new Failure(err);
      }
    },
    [enqueueSnackbar],
  );

  const getMaintenanceEvent = useCallback(
    async (eventId: string) => {
      try {
        const instance = getAPI();
        const res = await instance.get(`/maintenance_events/${eventId}`);
        return new Success(res.data);
      } catch (err) {
        enqueueSnackbar('Failure to get maintenance event', {
          variant: 'error',
        });
        return new Failure(err);
      }
    },
    [enqueueSnackbar],
  );

  const postMaintenanceEvent = useCallback(
    async <T extends PostBody>(body: T) => {
      try {
        const instance = await cuAPI();
        const res = await instance.post('/internal/maintenance_events', body);
        enqueueSnackbar('Registration Success', { variant: 'success' });
        return new Success(res.data as T);
      } catch (err) {
        enqueueSnackbar('Registration Failure', { variant: 'error' });
        return new Failure(err);
      }
    },
    [enqueueSnackbar],
  );

  const putMaintenanceEvent = useCallback(
    async <T extends PostBody>(body: T, eventId: string) => {
      try {
        const instance = await cuAPI();
        const res = await instance.put(
          `/internal/maintenance_events/${eventId}`,
          body,
        );
        enqueueSnackbar('Update Success', { variant: 'success' });
        return new Success(res.data as T);
      } catch (err) {
        enqueueSnackbar('Update Failure', { variant: 'error' });
        return new Failure(err);
      }
    },
    [enqueueSnackbar],
  );

  return {
    getMaintenanceEvents,
    getMaintenanceEvent,
    postMaintenanceEvent,
    putMaintenanceEvent,
  };
};
