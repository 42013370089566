import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  Grid2 as Grid,
  IconButton,
  styled,
  Toolbar,
} from '@mui/material';
import { useCallback } from 'react';
import { Link, Outlet } from 'react-router-dom';
import store from 'store2';
import auth from './api/auth';
import logo from './assets/logo.svg';

const App = () => {
  const handleClickSignout = useCallback(async () => {
    store.session.remove('webauto_auth_js_bearer');
    await auth.userManager.signOutRedirect();
  }, []);

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.01);' }}>
      <AppBar position="sticky">
        <Container maxWidth="md">
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid size={1}>
                <img
                  src={logo}
                  alt="Web.Auto"
                  width="40"
                  style={{ display: 'block' }}
                />
              </Grid>
              <Grid size={10}>
                <Grid container justifyContent="center" spacing={8}>
                  <Grid>
                    <Button component={Link} to="/">
                      Maintenance
                    </Button>
                  </Grid>
                  <Grid>
                    <Button component={Link} to="/status/">
                      Status
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={1}>
                <Box textAlign="right">
                  <IconButton onClick={handleClickSignout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} size="xs" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="md">
        <Box p={4}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
};

export default App;

const AppBar = styled(MuiAppBar)`
  background-color: white;
  box-shadow: none;
`;
