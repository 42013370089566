import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '../api/auth';

const SignOutCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    auth.userManager.signOutCallback().then(() => {
      navigate('/', { replace: true });
    });
  }, [navigate]);
  return <></>;
};

export default SignOutCallback;
