import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Stack,
  styled,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import Status from './pages/status';
import Maintenance from './pages/maintenance';
import auth, { userAtom } from './api/auth';
import { RecoilRoot, useRecoilValue } from 'recoil';
import SignInCallback from './pages/signin';
import { useMount } from 'react-use';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import MaintenanceRegister from './pages/maintenance/register';
import MaintenanceEdit from './pages/maintenance/edit';
import { SnackbarProvider } from 'notistack';
import store from 'store2';
import SignOutCallback from './pages/signout';

const AppRoutes = () => {
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();

  useMount(async () => {
    const { search, pathname } = window.location;
    const signoutPage = pathname.includes('/logout/callback');
    const hasParamCode = search.includes('code=');
    const hasParamState = search.includes('state=');
    const hasParamScope = search.includes('scope=');
    const savedPathName: string = store.session.get('pathname');
    if (!signoutPage && !hasParamCode && !hasParamState && !hasParamScope) {
      store.session.set('pathname', `${pathname}${search}`);
    } else {
      if (!savedPathName) {
        store.session.set('pathname', '/maintenance/');
      }
    }

    const loginAttempt =
      window.location.href.indexOf(
        import.meta.env.VITE_OIDC_LOGIN_CALLBACK_URI || '',
      ) > -1;
    if (!loginAttempt) {
      await auth.userManager.signInRedirect();
    } else {
      navigate(store.session.get('pathname') ?? '/maintenance/', {
        replace: true,
      });
      store.session.remove('pathname');
    }
  });

  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="login/callback/" element={<SignInCallback />} />
          <Route
            path="/"
            element={
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            }
          />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="maintenance/" element={<Maintenance />} />
        <Route path="maintenance/register/" element={<MaintenanceRegister />} />
        <Route path="maintenance/edit/:eventId" element={<MaintenanceEdit />} />
        <Route path="status/" element={<Status />} />
        <Route
          path="login/*"
          element={<Navigate replace to="/maintenance/" />}
        />
        <Route path="login/callback/" element={<SignOutCallback />} />
        <Route index element={<Navigate replace to="/maintenance/" />} />
        <Route
          path="*"
          element={
            <Box mt={18} textAlign="center">
              <Typography variant="h2">Not found.</Typography>
              <Box mt={8}>
                <Stack
                  direction="row"
                  spacing={4}
                  sx={{ justifyContent: 'center' }}
                >
                  <Button
                    component={Link}
                    to="/maintenance/"
                    variant="outlined"
                  >
                    Maintenance
                  </Button>
                  <Button component={Link} to="/status/" variant="outlined">
                    Status
                  </Button>
                </Stack>
              </Box>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <CssBaseline />
    <RecoilRoot>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            dateLibInstance={DateTime}
          >
            <SnackbarProvider
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <AppRoutes />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </RecoilRoot>
  </>,
);

const ProgressWrapper = styled(Box)`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
