import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import auth, { userAtom } from '../api/auth';

const SignInCallback = () => {
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const user = await auth.userManager.signInCallback();
      if (user) {
        setUser(user);
      }
    })();
  }, [setUser, navigate]);
  return <></>;
};

export default SignInCallback;
