import { useMount } from 'react-use';
import { useMaintenanceEvents } from '../../api/maintenance';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DISPLAY_SERVICE_NAMES, MaintenanceEvent } from '../../api/types';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
  Grid2 as Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPen,
  faPlus,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';

type MaintenanceCardProps = {
  data: MaintenanceEvent;
};

const MaintenanceCard = memo<MaintenanceCardProps>(({ data }) => {
  return (
    <Box mb={8}>
      <Paper>
        <Box p={6}>
          {/* Incident Level, Type */}
          <Box>
            <Grid container justifyContent="space-between">
              <Grid>
                <Grid container spacing={8}>
                  <Grid>
                    <Typography variant="caption" color="gray">
                      Type
                    </Typography>
                    <Typography variant="h6">{data.type}</Typography>
                  </Grid>
                  {data.incident_level && (
                    <Grid>
                      <Typography variant="caption" color="gray">
                        Incident Level
                      </Typography>
                      <Typography variant="h6">
                        {data.incident_level}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Button
                  size="small"
                  variant="outlined"
                  component={Link}
                  to={`/maintenance/edit/${data.id}/`}
                  startIcon={
                    <FontAwesomeIcon icon={faPen} style={{ width: 11 }} />
                  }
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Box>
          {/* Status */}
          <Box mt={2}>
            <Typography variant="caption" color="gray">
              Status
            </Typography>
            <Typography variant="h5">{data.status}</Typography>
          </Box>
          {/* Start - End */}
          <Box mt={2}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Grid container spacing={4}>
                  <Grid>
                    <Typography variant="caption" color="gray">
                      Start
                    </Typography>
                    <Typography variant="h6">
                      {DateTime.fromISO(data.start_time).toFormat(
                        'yyyy/MM/dd HH:mm',
                      )}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Box mt={5.5}>
                      <FontAwesomeIcon icon={faMinus} />
                    </Box>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" color="gray">
                      End
                    </Typography>
                    <Typography variant="h6">
                      {data.end_time
                        ? DateTime.fromISO(data.end_time).toFormat(
                            'yyyy/MM/dd HH:mm',
                          )
                        : 'unknown'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* Summary */}
          <Box mt={2}>
            <Typography variant="caption" color="gray">
              Summary - EN
            </Typography>
            <Box
              p={4}
              my={2}
              sx={{
                backgroundColor: colors.grey[50],
                borderRadius: 1,
              }}
            >
              <Typography>{data.summary.en}</Typography>
            </Box>
            <Typography variant="caption" color="gray">
              Summary - JA
            </Typography>
            <Box
              p={4}
              my={2}
              sx={{
                backgroundColor: colors.grey[50],
                borderRadius: 1,
              }}
            >
              <Typography>{data.summary.ja}</Typography>
            </Box>
          </Box>
          {/* Services */}
          <Box>
            <Typography variant="caption" color="gray">
              Services
            </Typography>
            <Box mt={2}>
              <Grid container spacing={4}>
                {data.target_services.map((service) => (
                  <Grid key={service}>
                    <Chip
                      size="small"
                      label={<>{DISPLAY_SERVICE_NAMES[service]}</>}
                      variant="filled"
                      color="primary"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          {/* meta */}
          <Box mt={2}>
            <Grid container spacing={12}>
              <Grid>
                <Typography variant="caption" color="gray">
                  ID
                </Typography>
                <Typography>{data.id}</Typography>
              </Grid>
              <Grid>
                <Typography variant="caption" color="gray">
                  Updated At
                </Typography>
                <Typography>
                  {DateTime.fromISO(data.last_updated_time).toFormat(
                    'yyyy/MM/dd HH:mm:ss',
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
});

MaintenanceCard.displayName = 'MaintenanceCard';

const Maintenance = () => {
  const { getMaintenanceEvents } = useMaintenanceEvents();
  const [events, setEvents] = useState<MaintenanceEvent[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const from =
    searchParams.get('from') ?? DateTime.now().toFormat('yyyy-MM-dd');
  const to =
    searchParams.get('to') ??
    DateTime.now().plus({ months: 1 }).toFormat('yyyy-MM-dd');

  const fromLuxon = useMemo(
    () => DateTime.fromISO(from).startOf('day'),
    [from],
  );
  const toLuxon = useMemo(() => DateTime.fromISO(to).endOf('day'), [to]);

  const isValidPeriod = useMemo(
    () => fromLuxon.isValid && toLuxon.isValid,
    [fromLuxon, toLuxon],
  );

  const handleChangeFromDate = useCallback(
    (date: DateTime | null) => {
      if (!date || !date.isValid) return;
      setSearchParams({
        from: date.toFormat('yyyy-MM-dd'),
        to,
      });
    },
    [setSearchParams, to],
  );

  const handleChangeToDate = useCallback(
    (date: DateTime | null) => {
      if (!date || !date.isValid) return;
      setSearchParams({
        from,
        to: date.toFormat('yyyy-MM-dd'),
      });
    },
    [setSearchParams, from],
  );

  const loadMaintenanceInfo = useCallback(async () => {
    if (!isValidPeriod) return;
    if (fromLuxon.startOf('day') > toLuxon.endOf('day')) return;

    setIsLoading(true);
    const startFrom = fromLuxon.toJSDate().toISOString();
    const startTo = toLuxon.toJSDate().toISOString();
    const res = await getMaintenanceEvents(startFrom, startTo);
    if (res.isSuccess()) {
      setEvents(res.value);
    } else {
      setEvents([]);
    }
    setIsLoading(false);
  }, [getMaintenanceEvents, isValidPeriod, fromLuxon, toLuxon]);

  const handleClickRefresh = useCallback(() => {
    loadMaintenanceInfo();
  }, [loadMaintenanceInfo]);

  useMount(() => {
    navigate(`/maintenance/?from=${from}&to=${to}`, {
      replace: true,
    });
  });

  useEffect(() => {
    loadMaintenanceInfo();
  }, [loadMaintenanceInfo, fromLuxon, toLuxon]);

  return (
    <>
      <Box>
        <Box my={4}>
          <Typography variant="h5" component="p">
            Maintenance
          </Typography>
          <Box mt={3}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid>
                    <DatePicker
                      label="From"
                      value={fromLuxon}
                      format="yyyy/MM/dd"
                      views={['year', 'month', 'day']}
                      onChange={handleChangeFromDate}
                      slotProps={{
                        textField: {
                          sx: {
                            width: 160,
                          },
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid>
                    <DatePicker
                      label="To"
                      value={toLuxon}
                      minDate={fromLuxon}
                      format="yyyy/MM/dd"
                      views={['year', 'month', 'day']}
                      onChange={handleChangeToDate}
                      slotProps={{
                        textField: {
                          sx: {
                            width: 160,
                          },
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Box ml={2}>
                      <Chip
                        size="small"
                        color="primary"
                        label={`${events.length} ${
                          events.length > 1 ? `events` : `event`
                        }`}
                        sx={{ lineHeight: 1 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid container spacing={4}>
                  <Grid>
                    <Button
                      startIcon={
                        <FontAwesomeIcon icon={faPlus} style={{ width: 14 }} />
                      }
                      component={Link}
                      to="/maintenance/register/"
                    >
                      Register
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      startIcon={
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          style={{ width: 14 }}
                        />
                      }
                      onClick={handleClickRefresh}
                      disabled={isLoading}
                    >
                      Refresh
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          {isLoading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : events.length === 0 ? (
            <Box mt={12} textAlign="center">
              <Typography variant="h3">Not found.</Typography>
            </Box>
          ) : (
            <Box mt={6}>
              {events.map((event) => (
                <MaintenanceCard key={event.id} data={event} />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Maintenance;
