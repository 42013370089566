import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { PostBody, useMaintenanceEvents } from '../../../api/maintenance';
import { Link, useParams } from 'react-router-dom';
import MaintenanceForm from '../../../components/MaintenanceForm/MaintenanceForm';
import { Inputs } from '../../../components/MaintenanceForm';
import { useMount } from 'react-use';
import { MaintenanceEvent } from '../../../api/types';

const MaintenanceEdit = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceEvent, setMaintenanceEvent] =
    useState<MaintenanceEvent | null>(null);
  const { getMaintenanceEvent, putMaintenanceEvent } = useMaintenanceEvents();
  const { eventId } = useParams();

  const handleRegister = useCallback(
    async (data: Inputs) => {
      if (!eventId) return;

      setIsRegistering(true);

      const body: PostBody = {
        start_time: data.start_time?.toJSDate().toISOString() || '',
        end_time: data.end_time?.toJSDate().toISOString() || '',
        type: data.type,
        incident_level: data.incident_level,
        target_services: data.target_services,
        status: data.status,
        summary: {
          en: data.summary_en,
          ja: data.summary_ja,
        },
      };

      if (data.type === 'scheduled') {
        delete body['incident_level'];
      }
      if (data.type === 'unplanned' && !data.is_end_time) {
        delete body['end_time'];
      }

      const res = await putMaintenanceEvent(body, eventId);

      if (res.isSuccess()) {
        //
      }

      if (res.isFailure()) {
        //
      }

      setIsRegistering(false);
    },
    [putMaintenanceEvent, eventId],
  );

  useMount(async () => {
    if (!eventId) return;
    const res = await getMaintenanceEvent(eventId);
    if (res.isSuccess()) {
      setMaintenanceEvent(res.value);
    }
    setIsLoading(false);
  });

  return (
    <Box my={4}>
      <Box mb={6}>
        <Breadcrumbs>
          <MuiLink component={Link} to="/maintenance/">
            Maintenance
          </MuiLink>
          <Typography color="text.primary">Edit</Typography>
        </Breadcrumbs>
      </Box>
      <Typography variant="h5">Edit Maintenance Event</Typography>
      <Box>
        {isLoading ? (
          <Box textAlign="center" mt={6}>
            <CircularProgress />
          </Box>
        ) : maintenanceEvent ? (
          <MaintenanceForm
            editData={maintenanceEvent}
            isRegistering={isRegistering}
            onSubmit={handleRegister}
          />
        ) : (
          <Box mt={12} textAlign="center">
            <Typography variant="h3">Not found.</Typography>
            <Box mt={8} textAlign="center">
              <Button component={Link} variant="outlined" to="/maintenance/">
                Maintenance List
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(MaintenanceEdit);
