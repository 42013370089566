import qs from 'qs';
import axios from 'axios';
import auth from './auth';

const baseURL = `https://${import.meta.env.VITE_STATUS_API_DOMAIN}/${
  import.meta.env.VITE_STATUS_API_VERSION
}`;

export const getAPI = () => {
  const instance = axios.create({
    baseURL,
  });
  return instance;
};

export const cuAPI = async () => {
  const tokenSource = await auth.getTokenSource();
  const instance = auth.getAxiosInstance(tokenSource);
  instance.defaults.baseURL = baseURL;
  instance.interceptors.request.use((config) => {
    config.paramsSerializer = {
      encode: (params) => qs.stringify(params),
    };
    return config;
  });
  return instance;
};

export const SERVICE_NAMES = [
  'auth',
  'drive',
  'evaluator',
  'fms-api',
  'fms-console',
  'fms-go',
  'fms-call',
  'scenario-editor',
  'software-update',
  'tier-iv-account',
  'vector-map-builder',
  'vehicle-data-search',
  'vehicle-data-upload',
  'web-auto-console',
] as const;
