import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { getAPI } from '.';
import { Failure, Success } from './types';

export const useStatusInfo = () => {
  const { enqueueSnackbar } = useSnackbar();

  const getStatusInfo = useCallback(async () => {
    try {
      const instance = await getAPI();
      const res = await instance.get('/service_status');
      return new Success(res.data);
    } catch (err) {
      enqueueSnackbar('Failure to get status', { variant: 'error' });
      return new Failure(err);
    }
  }, [enqueueSnackbar]);

  return { getStatusInfo };
};
